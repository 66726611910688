<template>
  <Teleport to="body">
    <div
      class="fixed inset-0 z-50 flex items-center justify-center bg-slate-500/90"
      @click.self="closeBridgeModal"
    >
      <WalletBridge
        :from-network="bridgeModalConfig?.fromNetwork"
        :to-network="bridgeModalConfig?.toNetwork"
        :from-currency="bridgeModalConfig?.fromCurrency"
        :to-currency="bridgeModalConfig?.toCurrency"
        :amount="bridgeModalConfig?.amount"
        :lock="bridgeModalConfig?.lock"
      />
    </div>
  </Teleport>
</template>

<script setup>
import { storeToRefs } from 'pinia';
import { useUiStore } from '@/store/ui';

const uiStore = useUiStore();
const { bridgeModalConfig, } = storeToRefs(uiStore);
const { closeBridgeModal, } = uiStore;
</script>
